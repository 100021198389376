#preview-contract {
	margin: 40px 0px;
	padding: 20px;
	text-indent: 0;
	width: 670px;
	background: #fff;
}

#preview-contract h2 {
	color: black;
	font-family: 'Times New Roman', serif;
	font-style: normal;
	font-weight: bold;
	text-decoration: none;
	font-size: 11pt;
}

#preview-contract .s1 {
	color: black;
	font-family: Georgia, serif;
	font-style: normal;
	font-weight: normal;
	text-decoration: none;
	font-size: 11pt;
}

#preview-contract .s2 {
	color: black;
	font-family: Georgia, serif;
	font-style: normal;
	font-weight: normal;
	text-decoration: none;
	font-size: 24pt;
}

#preview-contract h1 {
	color: black;
	font-family: 'Book Antiqua', serif;
	font-style: normal;
	font-weight: bold;
	text-decoration: underline;
	font-size: 12pt;
}

#preview-contract .s3 {
	color: black;
	font-family: 'Book Antiqua', serif;
	font-style: normal;
	font-weight: bold;
	text-decoration: none;
	font-size: 12pt;
}

#preview-contract .s4 {
	color: black;
	font-family: Georgia, serif;
	font-style: normal;
	font-weight: normal;
	text-decoration: none;
	font-size: 12pt;
}

#preview-contract p {
	color: black;
	font-family: 'Times New Roman', serif;
	font-style: normal;
	font-weight: normal;
	text-decoration: none;
	font-size: 11pt;
	margin: 0pt;
}

#preview-contract .s5 {
	color: black;
	font-family: 'Times New Roman', serif;
	font-style: normal;
	font-weight: normal;
	text-decoration: none;
	font-size: 11pt;
}

#preview-contract .s6 {
	color: black;
	font-family: 'Times New Roman', serif;
	font-style: normal;
	font-weight: bold;
	text-decoration: underline;
	font-size: 11pt;
}

#preview-contract li {
	display: block;
}

#preview-contract #l1 {
	padding-left: 0pt;
	counter-reset: c1 2;
}

#preview-contract #l1 > li > *:first-child:before {
	counter-increment: c1;
	content: counter(c1, decimal) '. ';
	color: black;
	font-family: 'Times New Roman', serif;
	font-style: normal;
	font-weight: normal;
	text-decoration: none;
	font-size: 11pt;
}

#preview-contract #l1 > li:first-child > *:first-child:before {
	counter-increment: c1 0;
}

#preview-contract #l2 {
	padding-left: 0pt;
	counter-reset: c2 1;
}

#preview-contract #l2 > li > *:first-child:before {
	counter-increment: c2;
	content: '(' counter(c2, lower-latin) ') ';
	color: black;
	font-family: 'Times New Roman', serif;
	font-style: normal;
	font-weight: normal;
	text-decoration: none;
	font-size: 11pt;
}

#preview-contract #l2 > li:first-child > *:first-child:before {
	counter-increment: c2 0;
}

#preview-contract #l3 {
	padding-left: 0pt;
	counter-reset: c2 1;
}

#preview-contract #l3 > li > *:first-child:before {
	counter-increment: c2;
	content: '(' counter(c2, lower-latin) ') ';
	color: black;
	font-family: 'Times New Roman', serif;
	font-style: normal;
	font-weight: normal;
	text-decoration: none;
	font-size: 11pt;
}

#preview-contract #l3 > li:first-child > *:first-child:before {
	counter-increment: c2 0;
}

#preview-contract table,
#preview-contract tbody {
	vertical-align: top;
	overflow: visible;
}
